








































































































































import PropertyCard, { PropertyCardMode } from '@/components/PropertyCard.vue'
import PropertyCardSkeleton from '@/components/PropertyCardSkeleton.vue'
import PropertyListModeToggle from '@/components/PropertyListModeToggle.vue'
import PropertyListSearch from '@/components/PropertyListSearch.vue'
import PropertyListFilters from '@/components/PropertyListFilters.vue'
import PropertyListMap from '@/components/PropertyListMap.vue'
import PaginationNav from '@/components/PaginationNav.vue'
import PaginationNavSkeleton from '@/components/PaginationNavSkeleton.vue'
import PropertyShareModal from '@/components/PropertyShareModal.vue'
import SiteSection from '@/components/SiteSection.vue'
import { useProperties } from '@/composition/properties'
import { getUsdPrice } from '@/composition/currency'
import { GuidIdTableType } from '@/services/utils'
import { PropertyViewModel } from '@/services/modules/property'
import { currentRoute } from '@/router'
import { Location } from 'vue-router'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'CategoryPropertyList',

  components: {
    SiteSection,
    PropertyCard,
    PropertyCardSkeleton,
    PaginationNav,
    PaginationNavSkeleton,
    PropertyListModeToggle,
    PropertyListSearch,
    PropertyListFilters,
    PropertyListMap,
    PropertyShareModal,
  },

  props: {
    categoryId: {
      type: String,
      required: true,
    },

    category: {
      type: String,
      default: '',
    },

    startingDate: {
      type: String,
      default: '',
    },

    endingDate: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const pageNumber = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.pageNumber &&
      typeof currentRoute.value.query.pageNumber === 'string'
        ? Number.parseInt(currentRoute.value.query.pageNumber)
        : 1
    )

    const searchBy = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.searchBy &&
      typeof currentRoute.value.query.searchBy === 'string'
        ? currentRoute.value.query.searchBy
        : ''
    )

    const bathroom = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.bathroom &&
      typeof currentRoute.value.query.bathroom === 'string'
        ? Number.parseInt(currentRoute.value.query.bathroom)
        : undefined
    )

    const bedroom = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.bedroom &&
      typeof currentRoute.value.query.bedroom === 'string'
        ? Number.parseInt(currentRoute.value.query.bedroom)
        : undefined
    )

    const minPrice = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.minPrice &&
      typeof currentRoute.value.query.minPrice === 'string'
        ? Number.parseInt(currentRoute.value.query.minPrice)
        : undefined
    )

    const maxPrice = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.maxPrice &&
      typeof currentRoute.value.query.maxPrice === 'string'
        ? Number.parseInt(currentRoute.value.query.maxPrice)
        : undefined
    )

    const minPrice2 = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.minPrice2 &&
      typeof currentRoute.value.query.minPrice2 === 'string'
        ? Number.parseInt(currentRoute.value.query.minPrice2)
        : undefined
    )

    const maxPrice2 = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.maxPrice2 &&
      typeof currentRoute.value.query.maxPrice2 === 'string'
        ? Number.parseInt(currentRoute.value.query.maxPrice2)
        : undefined
    )

    const orderBy = computed(() =>
      currentRoute.value &&
      currentRoute.value.query.orderBy &&
      typeof currentRoute.value.query.orderBy === 'string'
        ? currentRoute.value.query.orderBy
        : undefined
    )

    const propertyTypeList = computed(() => {
      let list: GuidIdTableType[] = []

      if (currentRoute.value && currentRoute.value.query.propertyTypeList) {
        const routeList = Array.isArray(
          currentRoute.value.query.propertyTypeList
        )
          ? currentRoute.value.query.propertyTypeList
          : [currentRoute.value.query.propertyTypeList]
        for (const item of routeList) {
          if (!item) continue
          list.push({ id: item })
        }
      }
      return list
    })

    const propertyAreaList = computed(() => {
      let list: GuidIdTableType[] = []

      if (currentRoute.value && currentRoute.value.query.propertyAreaList) {
        const routeList = Array.isArray(
          currentRoute.value.query.propertyAreaList
        )
          ? currentRoute.value.query.propertyAreaList
          : [currentRoute.value.query.propertyAreaList]
        for (const item of routeList) {
          if (!item) continue
          list.push({ id: item })
        }
      }
      return list
    })

    const rowsPerPage = 12

    const { data, isLoading, totalItems } = useProperties(
      computed(() => ({
        pageNumber: pageNumber.value,
        rowsPerPage: rowsPerPage,
        searchBy: searchBy.value,
        categoryId: props.categoryId,
        bathroom: bathroom.value,
        bedroom: bedroom.value,
        minPrice: getUsdPrice(minPrice.value),
        minPrice2: getUsdPrice(minPrice2.value),
        maxPrice: getUsdPrice(maxPrice.value),
        maxPrice2: getUsdPrice(maxPrice2.value),
        orderBy: orderBy.value,
        propertyTypeList: propertyTypeList.value,
        propertyAreaList: propertyAreaList.value,
        availableRentalStartingDate: props.startingDate,
        availableRentalEndingDate: props.endingDate,
        isDeleted: false,
      }))
    )

    const mode = ref<PropertyCardMode>('list')

    const resetFiltersRouteTo = computed<Location>(() => ({
      query: {},
    }))

    const selectedEstateProperty = ref<PropertyViewModel | null>(null)
    const isShareModalOpen = ref(false)

    watch(
      () => selectedEstateProperty.value,
      (newValue) => {
        isShareModalOpen.value = Boolean(newValue)
      }
    )

    return {
      data,
      isLoading,
      mode,
      pageNumber,
      rowsPerPage,
      totalItems,
      bathroom,
      bedroom,
      minPrice,
      maxPrice,
      orderBy,
      propertyTypeList,
      propertyAreaList,
      searchBy,
      resetFiltersRouteTo,
      selectedEstateProperty,
      isShareModalOpen,
    }
  },
})
