









































import PropertyCard from '@/components/PropertyCard.vue'
import { PropertyViewModel } from '@/services/modules/property'
import { getPropertyGoogleMapsLatLng } from '@/utils/property'
import {
  defineComponent,
  computed,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'

export default defineComponent({
  name: 'PropertyListMap',

  components: {
    PropertyCard,
  },

  props: {
    propertyList: {
      type: Array as PropType<PropertyViewModel[]>,
      required: true,
    },
  },

  setup(props) {
    const map = ref<google.maps.Map | null>(null)

    const mapCenter: google.maps.LatLngLiteral = {
      lat: 27.9499962,
      lng: -111.0166666,
    }

    const markers = computed(() =>
      props.propertyList.map((property) => ({
        property: property,
        options: {
          position: getPropertyGoogleMapsLatLng(property),
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 8.5,
            fillColor:
              property.categoryViewModel.toneViewModel.name === 'primary'
                ? '#ff681d'
                : '#0047ba',
            fillOpacity: 1,
            strokeOpacity: 1,
            strokeWeight: 2,
            strokeColor: '#fff',
          },
        } as google.maps.MarkerOptions,
      }))
    )

    watch(
      () => props.propertyList,
      () => {
        fitBounds()
      }
    )

    function fitBounds() {
      if (!map.value) return

      const bounds = new google.maps.LatLngBounds()

      for (const property of props.propertyList) {
        bounds.extend(getPropertyGoogleMapsLatLng(property))
      }

      map.value.fitBounds(bounds)
    }

    const infoWindowPos = ref<
      google.maps.LatLngLiteral | google.maps.LatLng | null
    >(null)
    const infoWinOpen = ref(false)

    const selectedProperty = ref<PropertyViewModel | null>(null)

    function toggleInfoWindow(
      property: PropertyViewModel,
      marker: google.maps.MarkerOptions
    ) {
      if (selectedProperty.value?.propertyId === property.propertyId) {
        infoWinOpen.value = !infoWinOpen.value
      } else {
        infoWinOpen.value = true
        selectedProperty.value = property

        infoWindowPos.value = marker.position || null
      }
    }

    return {
      map,
      mapCenter,
      markers,
      infoWindowPos,
      infoWinOpen,
      toggleInfoWindow,
      selectedProperty,
    }
  },
})
