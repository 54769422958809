





























import router, { currentRoute } from '@/router'
import i18n from '@/setup/i18n'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'PropertyListFilters',

  props: {
    searchBy: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const placeholder = computed(() => i18n.t('searchBy'))
    const internalSearchBy = ref(props.searchBy)

    watch(
      () => props.searchBy,
      (newValue) => {
        if (newValue === internalSearchBy.value) return
        internalSearchBy.value = newValue
      }
    )

    function onSearch() {
      if (props.searchBy === internalSearchBy.value) return
      router.push({
        query: {
          ...currentRoute.value?.query,
          pageNumber: '1',
          searchBy: internalSearchBy.value,
        },
      })
    }

    return {
      placeholder,
      internalSearchBy,
      onSearch,
    }
  },
})
