var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{staticClass:"flex rounded-full bg-gray-300 p-1 text-xl focus-within:ring-2"},[_c('BaseBox',{class:[
      'relative rounded-full py-1 px-2',
      _vm.modelValue === 'list'
        ? 'text-gray-50 bg-tertiary'
        : 'text-gray-600 cursor-pointer' ],attrs:{"title":_vm.listTitle,"tag":"label","for":"property-list-mode-list"}},[_c('BaseInputRadio',{staticClass:"absolute inset-0 pointer-events-none w-full h-full opacity-0",attrs:{"id":"property-list-mode-list","model-value":_vm.modelValue,"name":"property-mode","value":"list"},on:{"update":_vm.onUpdate}}),_c('BaseIcon',{staticClass:"pointer-events-none",attrs:{"name":"list-checkbox","source":"custom"}})],1),_c('BaseBox',{class:[
      'relative rounded-full py-1 px-2',
      _vm.modelValue === 'grid'
        ? 'text-gray-50 bg-tertiary'
        : 'text-gray-600 cursor-pointer' ],attrs:{"title":_vm.gridTitle,"tag":"label","for":"property-list-mode-grid"}},[_c('BaseInputRadio',{staticClass:"absolute inset-0 pointer-events-none w-full h-full opacity-0",attrs:{"id":"property-list-mode-grid","model-value":_vm.modelValue,"name":"property-mode","value":"grid"},on:{"update":_vm.onUpdate}}),_c('BaseIcon',{staticClass:"pointer-events-none",attrs:{"name":"all-application","source":"custom"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }