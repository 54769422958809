













import { usePagination } from '@/composition/pagination'
import { defineComponent, toRefs } from '@vue/composition-api'
import PaginationNavItem from './PaginationNavItem.vue'

export default defineComponent({
  name: 'PaginationNav',

  components: {
    PaginationNavItem,
  },

  props: {
    pageNumber: {
      type: Number,
      required: true,
    },

    rowsPerPage: {
      type: Number,
      required: true,
    },

    totalItems: {
      type: Number,
      required: true,
    },

    boundaryCount: {
      type: Number,
      default: 1,
    },

    siblingCount: {
      type: Number,
      default: 1,
    },
  },

  setup(props) {
    const { items } = usePagination(toRefs(props))

    return {
      items,
    }
  },
})
