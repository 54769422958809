



























import { PaginationNavItemType } from '@/composition/pagination'
import { currentRoute } from '@/router'
import { Location } from 'vue-router'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'PaginationNav',

  props: {
    type: {
      type: String as PropType<PaginationNavItemType>,
      required: true,
    },

    pageNumber: {
      type: Number,
      required: true,
    },

    selected: {
      type: Boolean,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const routeTo = computed<Location | undefined>(() =>
      props.disabled || !currentRoute.value || !currentRoute.value.name
        ? undefined
        : {
            name: currentRoute.value.name,
            query: {
              ...currentRoute.value.query,
              pageNumber: props.pageNumber.toString(),
            },
          }
    )

    return {
      routeTo,
    }
  },
})
