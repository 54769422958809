

























































import { PropertyCardMode } from '@/components/PropertyCard.vue'
import i18n from '@/setup/i18n'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'PropertyListModeTogle',

  model: {
    prop: 'modelValue',
    event: 'update',
  },

  props: {
    modelValue: {
      type: String as PropType<PropertyCardMode>,
      required: true,
    },
  },

  emits: {
    update: null,
  },

  setup(props, context) {
    const listTitle = computed(() => i18n.t('list'))
    const gridTitle = computed(() => i18n.t('grid'))

    function onUpdate(newVal: string) {
      context.emit('update', newVal)
    }

    return {
      listTitle,
      gridTitle,
      onUpdate,
    }
  },
})
